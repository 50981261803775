html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: top;
  background: transparent;
  font-family: "Hiragino Sans W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

body {
  line-height: 1;
  font-family: "Hiragino Sans W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: top;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

body {
  margin: 0;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}
.form-body h1,
.form-body h2{
    display:none;
}
h2 {
  font-size: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-base {
  /* background: #f2f2f2; */
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid #bbb; */
  max-width: 840px;
  padding: 25px 30px 35px;
  margin-bottom: 30px;
  clear: both;
}

.form-body {
  margin: auto;
  width: 100%;
}
.form-section {
  padding: 0px;
  margin: 0 auto 20px;
}

.form-title {
  font-size: 13px;
  color: #555;
  font-weight: bold;
}

.form-content {
  margin-top: 8px;
}

.form-text {
  border: 2px solid #cccccc;
  font-size: 15px;
  padding: 8px;
  width: 95%;
}

.form-text-group {
  display: flex;
  width: 95%;
}

.form-text-group .form-text {
  width: 29% !important;
}

.form-text-group .form-text + .form-text {
  margin-left: 2%;
}

.form-group-radio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: -8px;
}

.form-group-radio.is-nowrap {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: -16px;
}

.form-group-radio.is-flexible {
  display: flex;
  margin-top: -8px;
  margin-left: -16px;
}

.form-group-radio.is-fullwidth {
  margin-top: 0;
}

.form-group-radio .form-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  margin-top: 8px;
  width: 20%;
}

.form-group-radio.is-nowrap .form-label {
  margin-top: 0;
  margin-left: 16px;
  width: auto;
}

.form-group-radio.is-flexible .form-label {
  margin-left: 16px;
  width: auto;
}

.form-group-radio.is-fullwidth .form-label {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .form-group-radio .form-label {
    width: 50%;
  }

  .form-group-radio.is-nowrap {
    flex-wrap: wrap;
  }

  .form-group-radio.is-nowrap .form-label {
    width: 100%;
  }
}

.form-group-radio .form-label input[type="radio"],
.form-group-radio .form-label input[type="checkbox"] {
  width: 16px;
}

.form-error {
  margin-top: 8px;
  font-size: 13px;
  color: #f5222d;
  line-height: 1;
}

.form-button-base {
  margin: 15px auto 0;
  width: 100%;
  padding-top: 15px;
  text-align: center;
}
.form-button {
background: #4c9bd1;
    background-color: rgb(76, 155, 209);
    color: #ffffff;
    border: #4c9bd1;
padding: 1.071428em 2.142856em;
font-size: 16px;
width: 210px;
  font-weight: bold;
  cursor: pointer;
  float:right;
  margin-bottom:50px;
}
.form-backButton {
background: #efefef;
color: #999999;
    border: #efefef;
padding: 1.071428em 2.142856em;
font-size: 16px;
width: 210px;
  font-weight: bold;
  cursor: pointer;
  float:left;
  margin-bottom:50px;
  text-decoration:none;
  box-sizing: border-box;
}

.form-border-inn {
  margin: 30px auto;
  display: flex;
  max-width: 840px;
}
.form-border-inn a {
  background-image: url(https://jpn-expo.com/img/ico-login.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 5px center;
  border: 2px solid rgb(34, 172, 56);
  padding: 8px 15px 8px 28px;
  text-decoration: none;
  border-radius: 5px;
  font-weight:bold;
}
.form-border strong {
  background: url(img/logo.png) no-repeat left bottom;
  background-size: 275px;
  padding: 50px 0 2px 275px;
}
.form-body table {
  width: 100%;
}
.form-body table th {
  font-size: 15px;
  line-height: 150%;
  font-weight:normal;
  color: #333333;
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding: 0.357143em 1.071428em;
  width: 25%;
  vertical-align: middle;
}
.form-body table td {
  font-size: 11px;
  line-height: 150%;
  color: #4c4c4c;
  padding: 0.357143em 1.071428em;
  vertical-align: middle;
}
.form-body table input {
  background: #ffffff;
  font-size:13px;
  border: 1px solid #bbb;
  color: #000000;
  padding: 8px 7px;
  width: 100%;
  line-height:18px;
}
.form-body table tr:nth-child(1) input {
  width: 90%;
}
.form-body table tr:nth-child(2) input {
  width: 90%;
}
.form-body table tr:nth-child(3) input {
  width: 220px;
}
.form-body table tr:nth-child(4) input {
  width: 368px;
}
.form-body table tr:nth-child(5) input {
  width: 220px;
}
.form-body table tr:nth-child(6) input {
  width: 220px;
}
.form-body table tr:nth-child(7) input {
  width: 368px;
}
.form-body table tr:nth-child(8) input {
  width: 368px;
}
.form-body table tr input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance:textfield;
}
em {
  color: #ff0000;
}
.form-body table em {
  font-style: normal;
  color: #fff;
  font-size: 9px;
  background: #d00;
  padding: 1px 3px;
  margin-left: 4px;
}
.usccart_navi {
  width: 840px;
  margin: auto;
}
div.usccart_navi ul.ucart,
div.usccart_navi ol.ucart {
  display: flex;
}
div.usccart_navi li.ucart {
  background-color: #dedede;
  margin: 0px;
  color: #333333;
  font-weight: bold;
  flex: 1;
  text-align: center;
  list-style: none;
  position: relative;
  line-height:40px;
  font-size: 1em;
  padding: 0 .909095em 0 1.81818em;
}
div.usccart_navi li.usccart_customer {
  background: #000000;
  color: #ffffff;
}

div.usccart_navi li.usccart_customer:after {
  content: "";
  position: absolute;
  top: 0;
  right: -14px;
  border-left: 20px solid #000;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  transform-origin: right top;
  transform: scaleX(0.7);
  z-index: 1;
}

div.usccart_navi li.uscdelivery:after {
  content: "";
  position: absolute;
  top: 0;
  right: -14px;
  border-left: 20px solid #dedede;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  transform-origin: right top;
  transform: scaleX(0.7);
  z-index: 2;
}
.page-seminar {
  background-color: #1a597e;
  font-size: 16px;
  display: flex;
  min-height: calc(100vh - 48px);
  padding: 24px 18px;
}

.seminar-secondary {
  width: 200px;
}
.seminar-secondary .seminar-secondary-logo {
    text-align:center;
}
.seminar-secondary .seminar-secondary-logo img {
  width: 150px;
}


.seminar-main {
  flex: 1;
  padding-left: 12px;
}

.seminar-list {
  margin-top: 14px;
  margin-bottom: 32px;
  height: calc(100vh - 300px);
  overflow: auto;
}

.seminar-list__title {
  background-color: #17b438;
  color: #fff;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  padding: 6px 8px;
}

.seminar-list__body {
  list-style: none;
  background-color: #fff;
  padding: 14px 8px;
}

.seminar-list__item-title {
  color: #3273eb;
  font-weight: bold;
  font-size: 0.8em;
}

.seminar-list__item-body {
  margin-top: 8px;
}

.seminar-list__item {
  cursor: pointer;
  margin-bottom: 15px;
}

.seminar-list__item + .seminar-list__item {
  border-top: solid 1px #cccccc;
  padding-top: 15px;
}

.seminar-list__item-speaker {
  display: flex;
  margin-top: 8px;
}

.seminar-list__item-speaker-photo {
  width: 52px;
}

.seminar-list__item-speaker-photo img {
  height: auto;
  width: 100%;
}

.seminar-list__item-speaker-name {
  flex: 1;
  font-size: 0.75em;
  line-height: 1.25;
  padding-left: 8px;
}

.seminar-btn {
  background: url(https://vost-static.s3.ap-northeast-1.amazonaws.com/jpn-exhibition-hall.com/bg-seminar-btn.png)
    no-repeat center center;
  background-size: 100%;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seminar-btn:hover {
  opacity: 0.8;
}

.seminar-secondary .seminar-btn {
  /* (35 * 200 / 203) */
  height: 34px;
  width: 200px;
}

.seminar-video {
  background-color: #000;
  padding: 20px 0;
}

.seminar-video__container {
  margin: auto;
  width: 80%;
}

.seminar-video__title {
  /* background: url(https://vost-static.s3.ap-northeast-1.amazonaws.com/jpn-exhibition-hall.com/bg-seminar-title.png)
    no-repeat center center; */
  background-size: 100%;
  color: #fff;
  font-size: 1.25em;
  font-weight: bold;
  display: flex;
  align-items: center;
  /* (35 * 720 / 203) */
  padding: 20px 0 30px;
}

.seminar-video__video {
  /* video size: 640 : 360 */
  /* height: 405px; */
}

.seminar-video__buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.seminar-video__buttons .seminar-btn {
  /* (35 * 300 / 203) */
  height: 52px;
  width: 300px;
}

.seminar-video__buttons .seminar-btn + .seminar-btn {
  margin-left: 16px;
}

.seminar-survey {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 320px;
  height: 450px;
  background-color: #fff;
  padding: 16px;
  overflow-y: auto;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
}

.seminar-video__info {
  margin-top: 32px;
  display: none;
}

.seminar-video__info-title {
  color: #fff;
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 12px;
}

.seminar-video__info-body {
  background-color: #fff;
  display: flex;
  padding: 10px;
}

.seminar-video__info-speaker {
  display: flex;
  margin-right: 96px;
}

.seminar-video__info-speaker-photo {
  width: 80px;
}

.seminar-video__info-speaker-photo img {
  height: auto;
  width: 100%;
}

.seminar-video__info-speaker-name {
  flex: 1;
  line-height: 1.5;
  padding-top: 8px;
  padding-left: 16px;
}

.companiesTop .seminar-list__body {
  background: #2768b2;
  height: 300px;
  overflow: scroll;
}
.companiesTop .seminar-list__item-speaker-photo {
  width: 32px;
}
.companiesTop .seminar-list__item {
  background: #ffffff;
  padding: 10px;
}
.companiesTop .seminar-list__item a {
  text-decoration: none;
  color: #000000;
}
.companiesTop .seminar-list__item-title {
  font-size: 0.9em;
}
.company__images {
  display: flex;
  list-style: none;
}

.company__image img {
  height: auto;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 15px;
}

.company__video {
  display: block;
  margin: auto;
  width: 80%;
}

.company__logo {
  max-height: 45px;
  margin-bottom: 20px;
}

.welcome {
  background: url(https://vost-static.s3.ap-northeast-1.amazonaws.com/jpn-exhibition-hall.com/bg_uploader.jpg)
    no-repeat center center;
  background-size: cover;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.welcome h1,
.welcome p {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 1em;
  text-align: center;
}

.companies__links,
.company__links {
  display: flex;
  justify-content: space-around;
  margin: 16px auto 32px auto;
}

.companies__link,
.company__link {
  color: #fff;
  font-size: 12px;
  text-decoration: underline;
}

.company__links {
  margin-bottom: 16px;
}

.company__link {
  color: blue;
}

.overlay {
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow-y: auto;
}

/*********************

 sp companyList

**********************/
.headerImg {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.headerImg img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  -webkit-backface-visibility: hidden;
  vertical-align: bottom;
}
.companies {
  background: #2768b2;
  padding: 15px 0 20px;
}

.companies img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  -webkit-backface-visibility: hidden;
  vertical-align: bottom;
}

.companies .center {
  margin: auto;
  text-align: center;
}
.companies .txtRemarks {
  margin: 0 0 15px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}
.companies .txtRemarks span {
  font-size: 10px;
}
.companiesTop h1 {
  font-size: 20px;
  background: #0d1c5f;
  color: #ffffff;
  text-align: center;
  padding: 15px 5px;
  margin-bottom: 0;
  margin-top: 0;
}

.companies__list {
  width: 95%;
  margin: auto;
}
.companies__list li {
  list-style: none;
  margin: 0 0 8px;
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.5);
}
.companies__list li a {
  font-size: 13px;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  position: relative;
  padding: 15px 5px 15px 15px;
  background: rgba(255, 255, 255, 1);
  color: #555555;
  font-weight: bold;
}
.companies__list li a:hover {
  background: rgba(255, 255, 255, 0.9);
}
.companies__list li a::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 5px;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #c7aed5;
}
.companiesTop:before {
  background: url(img/logo.png) center center no-repeat;
  background-size: auto 40px;
  height: 60px;
  content: "";
  display: block;
}
/*********************

 sp companyList

**********************/
.company {
  width: 100%;
  max-width: 415px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
.company img {
  max-width: 80%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  -webkit-backface-visibility: hidden;
  vertical-align: bottom;
}
.company .center {
  margin: auto;
  text-align: center;
}
.company .txtRemarks {
  margin: 20px auto !important;
  color: #0d1c5f;
  font-size: 13px;
}
.company .Inner {
  max-width: 95%;
  margin: 0 auto;
  padding: 30px 0;
}
.company h1 {
  font-size: 20px;
  background: #0d1c5f;
  color: #ffffff;
  text-align: center;
  padding: 15px 5px;
  margin-bottom: 20px;
  margin-top: 0;
}

.company h2 {
  padding: 10px 5px;
  color: #ffffff;
  text-align: center;
  background: #2868b1;
  font-size: 16px;
}

.company__images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0 auto 20px;
  padding: 0;
}
.company__images li {
  -webkit-flex-basis: 50%;
  -ms-flex-basis: 50%;
  flex-basis: 50%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 5px;
}

.company video {
  margin: 25px auto;
  padding: 0;
  border: 1px solid #000;
  background: #000;
  width: 92%;
}
.company__video {
  width: 100%;
  height: auto;
}

.BtnBox {
  padding: 40px;
  background: #f2f2f2;
}
.BtnBox a {
  color: #fff;
  background-color: #619c39;
  border-radius: 100vh;
  padding: 10px 25px;
  margin: 0;
  text-decoration: none;
  line-height: 1;
  font-weight: bold;
}
.BtnBox a:hover {
  color: #fff;
  background: #d3e2cd;
}
.boothText {
  text-align: left;
  font-size: 13px;
  line-height: 21px;
  width: 92%;
  margin: 0 auto 25px;
}


footer{
width: 100% !important;
background: #efefef !important;
margin: 0 !important;
padding: 20px 0 !important;
background: #f2f2f2 !important;
clear:both;
}
footer p{
font-size: 12px !important;
line-height: 24px !important;
text-align: left !important;
color: #aaaaaa !important;
width: 92% !important;
margin: auto !important;
}
footer p a{
color: #aaaaaa !important;
text-decoration:none;
}
/*********************

 sp Login

**********************/
@media only screen and (max-width: 680px) {
  .usccart_navi {
    width: 92%;
  }
  div.usccart_navi li.ucart {
    font-size: 10px;
  }
  div.usccart_navi li.usccart_customer:after,
  div.usccart_navi li.uscdelivery:after {
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
  }
  .form-base {
    padding: 10px 30px 35px;
  }
  .form-body h1 {
    font-size: 20px;
    padding: 10px 0;
    margin: 0;
  }
  .form-body table th,
  .form-body table td {
    display: block;
    width: 100%;
    padding: 5px 0;
  }
  div.usccart_navi li.ucart {
    font-size: 10px;
  }
  .form-body p {
    text-align: left !important;
    font-size: 13px !important;
    line-height: 21px !important;
  }
  .form-button {
    font-size: 15px;
    width: 100%;
    padding: 18px 0;
  }
  .form-border strong {
    background-size: 180px;
    margin-left: 10px;
    padding: 34px 0 0 180px;
  }
  .form-border-inn a {
    margin-right: 10px;
    font-size: 12px !important;
    font-weight: bold;
  }
  .form-body table input {
    font-size: 16px;
    padding: 8px;
  }
}

/*********************

 sp Seminar

**********************/
@media only screen and (max-width: 680px) {
+  .page-seminar {
    display: block;
  }
  .seminar-secondary {
    width: 100%;
  }
  .seminar-list {
    height: 150px;
    margin-bottom: 10px;
  }
  .seminar-list .seminar-list__item-body {
    display: none;
  }
  .seminar-secondary .seminar-btn {
    margin: 0 auto 20px;
    height: 24px;
    font-size: 13px;
    width: 140px;
  }
  .seminar-main {
    padding: 0;
  }
  .seminar-video__title {
    font-size: 0.9em;
    padding: 5px 0 15px;
  }
  .seminar-secondary .seminar-secondary-logo img {
    width: 100px;
  }
  .seminar-video__buttons .seminar-btn {
    font-size: 0.8em;
    width: 200px;
    margin: auto;
  }
  .seminar-video__buttons {
    display: block;
  }
  .seminar-video__buttons .seminar-btn + .seminar-btn {
    margin: 0 auto 10px;
  }
  .seminar-list__item-title {
    font-size: 0.7em;
  }
  .seminar-list__item {
    margin-bottom: 10px;
  }
  .seminar-list__item + .seminar-list__item {
    padding-top: 10px;
  }
}

